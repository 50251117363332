import { recaptcha } from "@avldev/gatsby-active-campaign-forms"
import { cloudFunctionEndpoint, siteKey } from "../../config/recaptcha"

export const initRecaptcha = () => {
  recaptcha.initRecaptchaV3(siteKey)
}

export const isSpam = async () => {
  return await recaptcha.looksLikeSpam(siteKey, cloudFunctionEndpoint)
}
